import React, { useEffect, useState } from 'react'
import './Manufacturers.css'
import { Link } from 'react-router-dom';
import Product from '../Product/Product';
import { Helmet } from 'react-helmet';

const Manufacturers = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      },[]);

   const [isComponentsVisible, setIsComponentsVisible] = useState(false);

   const handleToggleComponents = () => {
      setIsComponentsVisible(!isComponentsVisible);
    };

  return (
    <div className='mnf-container'>
      <Helmet>
        <title>High-Quality Solenoid Valves by AutoMikroTekh - Precision Engineering You Can Trust</title>
        <meta name="description" content="Explore our range of high-quality solenoid valves at AutoMikroTekh. Discover the benefits of our precision-engineered valves for various industrial applications, ensuring reliability and efficiency." />
        <link rel="canonical" href="https://automikrotekh.com/air-bearing-spindle-manufacturer-chennai" />
      </Helmet>
        <div>
            <h1>Why Choose AUTO MIKRO TEKH</h1>
            <p className='fp'><span>Auto Mikro Tekh</span> is recognized as the <span>leading manufacturer of air bearing spindles and pneumatic valves, including solenoid valves and spool valves, in Chennai</span>.
            </p>
            <Link to={'/solenoid-valves-manufacturer-chennai'}><p className='pmt-link'>EXPLORE OUR VARIOUS PNEUMATIC VALVES</p></Link>
            <div className='pr-card'>
              <Product/>
            </div>
            
            {/* <p className='fp'>With a commitment to excellence and precision engineering, we offer customized and optimized solutions tailored
            to meet the specific requirements of our clients. Whether you need standard spindles or bespoke designs, our team is
            dedicated to delivering high-quality products that enhance your operational efficiency.</p> */}
   
            <p className='fp'>While there are several manufacturers in Chennai, <span>Auto Mikro Tekh</span> distinguishes itself through a commitment
            to quality,innovation, and customer satisfaction. Here’s why we are the preferred choice for many businesses:</p>

            <div className='whycard-container'>
                <div className='why-cards'>
                    <h2><i className="fas fa-cogs" aria-hidden="true"></i> Customized Solutions:</h2>
                    <p>We offer tailored solutions to meet the specific needs of our clients.</p>
                </div>
                <div className='why-cards'>
                    <h2><i className="fas fa-microchip" aria-hidden="true"></i> Cutting-Edge Technology:</h2>
                    <p>Our spindles are manufactured using the latest technology to ensure optimal performance.</p>
                </div>
                <div className='why-cards'>
                    <h2><i className="fas fa-headset" aria-hidden="true"></i> Expert Support:</h2>
                    <p>Our team of experts is always available to provide support and guidance, from selection to installation and beyond.</p>
                </div>
                <div className='why-cards'>
                    <h2><i className="fas fa-tags" aria-hidden="true"></i> Competitive Pricing:</h2>
                    <p>We offer high-quality products at competitive prices, ensuring excellent value for our clients.</p>
                </div>
            </div>
        </div>
        <div>
            <h1  onClick={handleToggleComponents} style={{ cursor: 'pointer' }}>Factors to Consider When Selecting a Manufacturer <i className={`fa-solid ${isComponentsVisible?'fa-circle-minus':'fa-plus-circle'}`}></i></h1>
            <p className='fp'><span>Choosing the right air bearing spindle manufacturer</span> is crucial for ensuring you get the best product for your needs.
                 Here are some key factors to consider:</p>
            <div className={`whycard-container content ${isComponentsVisible  ? 'visible' : ''}`}>
                <div className="why-cards">
                    <h2><i className="fas fa-check-circle" aria-hidden="true"></i>Quality and Precision</h2>
                    <p>Ensure that the manufacturer adheres to high-quality standards and precision engineering practices.
                    <span>Look for ISO certifications</span>  or other industry-specific quality standards.</p>
                </div>
                <div className="why-cards">
                    <h2><i className="fas fa-cogs" aria-hidden="true"></i>Customization Options</h2>
                    <p>Depending on your specific requirements, you may need custom-designed spindles. Check if the manufacturer <span>offers customization services</span>  to meet your unique needs.</p>
                </div>
                <div className="why-cards">
                    <h2><i className="fas fa-headset" aria-hidden="true"></i>Technical Support and Service</h2>
                    <p>Reliable technical support and after-sales service are crucial for maintaining the performance of your air bearing spindles. Choose a supplier known for <span>excellent customer service and support</span> .</p>
                </div>
                <div className="why-cards">
                    <h2><i className="fas fa-tags" aria-hidden="true"></i> Cost and Value</h2>
                    <p>While cost is an important factor, it’s essential to consider the overall value. Opt for suppliers who offer a <span>good balance of quality and affordability</span> .</p>
                </div>
            </div>
        </div>
        <div>
            <h2>Conclusion</h2>
            <p> Selecting the right <span>Air Bearing Spindle Manufacturer</span>  is essential for ensuring precision and reliability in your operations.
                While there are several reputable manufacturers in Chennai,<span> AUTO MIKRO TEKH</span> stands out for its commitment to quality,
                innovation, and customer satisfaction. By considering the factors outlined above, you can find a manufacturer that meets
                your specific needs and helps drive your business forward.
                Ready to upgrade your equipment? Start by exploring the <span>Top Air Bearing Spindle Manufacturers in Chennai</span> today!</p>
        </div>
    </div>
  )
}

export default Manufacturers