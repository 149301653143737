import React from 'react'
import './Laboratory.css'
import creditsicon from '../../assets/creditsicon.webp'

const Laboratory = () => {
  return (
    <div className='lab-container'>
         <h2>High Class Calibration,Testing and Quality Assurance Laboratory</h2>
         <div className="laboratory-container">
               <img className='creditsicon' src={creditsicon} alt="NABL" />
               <p className='credits-details'>The Eaagle View ( a unit of Auto Mikro
                Tech ) - NABL ACCREDITION LAB
                under the same roof is used for Calibration
                & Components measurement services.
                - A well equipped infrastructure for
                covering the full range of Mechanical
                Calibration and Testing to support Nano technology based domain also.
                </p>
         </div>
    </div>
  )
}

export default Laboratory