import React, { useEffect } from 'react'
import './Iso.css'

const Iso = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      },[]);
  return (
    <div className='iso-container'>
        <div>
            <h1>What is ISO 9001:2008?</h1>
            <p className='fp'><span>ISO 9001:2008</span> is an internationally recognized standard for <span>quality management systems (QMS)</span>.
                It provides a framework for organizations to ensure that they consistently meet customer and regulatory requirements while
                striving for continuous improvement. The ISO 9001:2008 standard is based on several quality management principles,
                including a strong customer focus, the involvement of top management, a process approach, and continual improvement.</p>
            <div>
                <h2>Our Quality Management System:</h2>
                <p>Our ISO 9001:2008 certification demonstrates that we have <span>implemented a robust Quality Management System</span>  that encompasses 
                all aspects of our operations.This includes:</p>
            </div>
            <div>
                <h2>Customer Focus:</h2>
                <p>We place a high priority on <span>understanding </span> and <span>meeting the needs</span>  and <span>expectations of our customers</span>.
                 We actively seek customer feedback and use it to improve our products and services continuously.</p>
            </div>
            <div>
                <h2>Leadership:</h2>
                <p>Our leadership team is committed to <span> maintaining a quality culture</span> and ensuring that our quality objectives are aligned
                 with our strategic goals. They provide direction and support to all employees to achieve these objectives.</p>
            </div>
            <div>
                <h2>Engagement of People:</h2>
                <p><span>We believe that our employees are our greatest asset</span> . We invest in their development and ensure they have the necessary 
                skills and resources to contribute to our quality goals.</p>
            </div>
            <div>
                <h2>Continuous Improvement:</h2>
                <p> We are committed to <span>continuously improving</span>  our processes, products, and services. We use data and feedback to identify areas
                 for improvement and implement effective solutions.</p>
            </div>
            <div>
                <h2>Evidence-Based Decision Making: </h2>
                <p>We make <span>decisions based on the analysis and evaluation of data</span> to ensure they are well-informed and effective.</p>
            </div>
            <div>
                <h2>Relationship Management:</h2>
                <p> We value our <span>relationships with customers, suppliers, and other stakeholders</span> . We work collaboratively with them to achieve mutual benefits.</p>
            </div>
        </div>
        <div>
            <h1>Benefits of Our ISO 9001:2008 Certification</h1>
                <h2>Enhanced Customer Satisfaction:</h2>
                    <p>By consistently meeting customer requirements and striving to exceed their expectations, we enhance customer satisfaction and build long-term relationships.</p>

                <h2>Improved Efficiency:</h2>
                    <p>Our process-based approach helps us streamline operations, reduce waste, and improve efficiency.</p>
                <h2>Increased Credibility:</h2>
                    <p>ISO 9001:2008 certification is recognized globally, providing our customers and stakeholders with confidence in our commitment to quality.</p>
                <h2>Risk Management:</h2>
                    <p>By identifying and addressing risks and opportunities, we enhance the stability and reliability of our operations.</p>
                <h2>Continuous Improvement: </h2>
                    <p>Our focus on continuous improvement helps us stay competitive and responsive to changing market needs.</p>
        </div>
        <div>
            <h2>Commitment to Excellence</h2>
            <p> Our ISO 9001:2008 certification is a testament to our commitment to quality and excellence.
                 We continuously strive to improve our processes, products, and services to meet and exceed customer expectations.
                 <span>At AutoMikroTekh, quality is not just a goal; it is a fundamental aspect of our business.</span>
                For more information about our ISO 9001:2008 Quality Management System and how it benefits you, please feel free to contact us.</p>
        </div>
    </div>
  )
}

export default Iso