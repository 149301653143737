import React, { useCallback, useEffect, useMemo } from 'react'
import './ProductPage.css'
import { useNavigate, useParams } from 'react-router-dom'
import ProductList from '../ProductObjects/ProductObject'
import Card from '../Card/Card'
import { Helmet } from 'react-helmet'


const ProductPage = () => {
  const navigate=useNavigate();

  const {url}=useParams()
  const product=ProductList.find((product)=>product.url===url)

  const handleProductChange = useCallback((url) => {
    navigate(`/product/${url}`);
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[handleProductChange]);


  const similarProducts = useMemo(() => {
    if (product) {
      const keywords = product.name.split(' ').filter(word => word.length > 2);
      return ProductList.filter((item) => {
        return keywords.some(keyword => item.name.toLowerCase().includes(keyword.toLowerCase()) && item.productId !== product.productId);
      });
    }
    return [];
  }, [product]);



  return (
    <div className='productpage-container'>
        <Helmet>
            <title>{product.name} - AutoMikroTekh</title>
            <meta name="description" content={product.Meta} />
            <link rel="canonical" href={`https://automikrotekh.com/product/${product.url}`} />
        </Helmet>

        <div className='products-name-container' id='other'>
           <h2>Other Products</h2>
           <div className='products-namelist'>
            {ProductList.map((item,index)=>(
                <h2 key={index} className='names' onClick={()=>handleProductChange(item.url)}>{item.name}</h2>
            ))}
           </div>
        </div>

        <div className='product-right'>
            <div className='product-clicked'>
                <h1>{product.name}</h1>
                <ul>
                 {product.use1&&<li>{product.use1}</li>}
                 {product.use2&&<li>{product.use2}</li>}
                 {product.use3&&<li>{product.use3}</li>}
                 {product.use4&&<li>{product.use4}</li>}
                 {product.use5&&<li>{product.use5}</li>}
                </ul>
                <div className='pdf'>
                  <div className='img-cat'>
                    <img src={product.image} alt={product.alt} className='product-image' />
                    <a href={product.pdf} target="_blank" rel="noopener noreferrer" className='catalog'>
                      <i className="fa-solid fa-file-pdf fa-lg"></i>
                      Catalogue 
                    </a>
                  </div>

                  {product.data&&<div className='specification'>
                    <h2>Product Specifications</h2>
                    <table border="1">
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {product.data.map((row, index) => (
                                <tr key={index}>
                                    <td className='property'>{row.property}</td>
                                    <td>{row.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                  }
                </div>
            </div>
            
            <div className="similar-products" id='similar'>
              <h2>Similar Products</h2>
              <div className="products">
                {similarProducts.length>0?similarProducts.map((item,index)=>(
                      <Card item={item} key={index}/> 
                )):
                  <><div>No Similar Products</div></>
                }
              </div>
            </div>
        </div>
        
    </div>
  )
}

export default ProductPage