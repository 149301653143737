import React from 'react'
import './Aim.css'
import three from '../../assets/box1.webp'
import lchain from '../../assets/chain.png'
import rchain from '../../assets/rightchain.png'

const Aim = () => {
  return (
    <div className="aimcontainer">
     <h2>Our Focus</h2>
    <div className='aim-container'>
      
       <div className="all">

            <div className="cleft">
                <p>Complex Precision</p>
                <p> & Machining Center</p>
            </div>
            <img className='chains' src={lchain} alt="img" />
            <div className='center'>
                <img className='three' src={three} alt="img" />
                <div className="ccenter">
                    <p>In house High class</p>
                    <p> Calibration Testing</p>
                    <p> & QA Laboratory</p>
                </div>
            </div>
           
            <img className='chains rchain'  src={rchain} alt="img" />
            <div className="cright">
                <p>Skill Development</p>
                <p> Center</p>
            </div>
               
       </div>
    </div>
          
    </div>
  )
}

export default Aim