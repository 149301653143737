import React from 'react'
import './Quality.css'
import isoicon from '../../assets/isoicon.webp'
import { useNavigate } from 'react-router-dom'


const Quality = () => {
  const navigate=useNavigate();

  const handleclick=()=>{
    navigate("/iso-certified")
  }
  return (
    <div className="qlt-container">
        <h2>ISO Certified</h2>
        <div className='quality-container'>   
            <img className='misoicon' src={isoicon} alt="iso" onClick={()=>handleclick()} />
            <div className="quality-details">
               <p className='qdp1'>At Auto Mikro Tekh, we are dedicated to providing the highest quality products and services to our customers.
               Our commitment to excellence is demonstrated through our ISO 9001:2008 Quality Management System certification,
               which ensures that we adhere to international standards for quality management and continuous improvement.</p>
               <p>Auto Mikro Tekh is certified to
                ISO : 9001 : 2008 Quality Management
                Systems</p>
            </div>
            <img className='isoicon' src={isoicon} alt="iso" onClick={()=>handleclick()} />
        </div>
    </div>
  )
}

export default Quality