import React from 'react'
import './Aboutus.css'
import company from '../../assets/companybuilding.webp'

const Aboutus = () => {
  return (
    <div className="abt-container" id='about'>
        <h2>About us</h2>
        <div className='aboutus-container'>
            <img src={company} alt="" />
            <div className="about-details">
                <h2>We Specialize in Air Bearing Spindles, High-Quality Solenoid Valves and Spool Valves</h2>
                
                <div className="two-text">
                    <p className='about-t1'><span> Auto Mikro Tekh </span>is part of a group
                    of businesses <span>founded in 1983 </span> by the
                    Visionary <span>R. Gopalakrishnan. </span>  
                    The company is housed in a 7000 sq.
                    feet factory buildings located adjacent
                    to the Chennai IT Highway corridor
                    and is easily accessible from all parts
                    of the city</p>

                    <p className='about-t2'>A most <span>PREFERRED SUPPLIER</span> for
                    all our <span> VALUABLE CUSTOMERS.</span></p>
                </div>
            </div>
        </div>
            
    </div>
  )
}

export default Aboutus