import React from 'react'
import './Card.css'
import { useNavigate } from 'react-router-dom'

const Card = ({item}) => {

  const navigate=useNavigate();


  const handleClick=(productId,url)=>{
    if(productId!==0)
     navigate(`/product/${url}`)
  }
  
  return (
    <div onClick={()=>handleClick(item.productId,item.url)} className='card-container'>  
        <img className='card-image' src={item.image} alt={item.alt} />
        <h2>{item.name}</h2>
    </div>
  )
}

export default Card