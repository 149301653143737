import React from 'react'
import './Equipments.css'

import worker1 from  '../../assets/worker1.webp'
import worker2 from  '../../assets/worker2.webp'
import worker3 from  '../../assets/worker3.webp'
import worker4 from  '../../assets/worker4.webp'
import worker5 from  '../../assets/worker5.webp'
import worker6 from  '../../assets/worker6.webp'
import worker7 from  '../../assets/worker7.webp'
import worker8 from  '../../assets/worker8.webp'
import worker9 from  '../../assets/worker9.webp'
import worker10 from  '../../assets/worker10.webp'
import Card from '../Card/Card'


const equipmentDetails=[
    { 
        "name":"Laboratory",
        "image":worker1,
        "productId":0,
        "alt":"Laboratory"
    },
    {    "name":"Laboratory",
        "image":worker2,
        "productId":0,
        "alt":"Laboratory"
    },
    {
        "name":"CMM - Co ordinate Measuring Machine",
        "image":worker7,
        "productId":0,
        "alt":"CMM - Co ordinate Measuring Machine"
    },
    {

        "name":"CONTOUR – Axis contour",
        "image":worker9,
        "productId":0,
        "alt":"CONTOUR – Axis contour"
    },
    {
        "name":"VMS Video Measuring System",
        "image":worker5,
        "productId":0,
        "alt":"VMS Video Measuring System"
    },
    {
        "name":"Surf Coder",
        "image":worker4,
        "productId":0,
        "alt":"Surf Coder"
    },
    { 
        "name":"Universal length Measuring",
        "image":worker6,
        "productId":0,
        "alt":"Universal length Measuring"
    },
    {
        "name":"2D Height Gauge",
        "image":worker8,
        "productId":0,
        "alt":"2D Height Gauge"
    },
    {
        "name":"Caliper Checker",
        "image":worker3,
        "productId":0,
        "alt":"Caliper Checker"
    },
    {
        "name":"Laser Scan Micro Meter",
        "image":worker10,
        "productId":0,
        "alt":"Laser Scan Micro Meter"
    },
]



const Equipments = () => {

  return (
    <div className='eqp-container'>
        <h2 className='eqp-h2'>High Class Calibration, Testing and
        Quality Assurance laboratory</h2>
        <div className="equipments-container">
            {equipmentDetails.map((item,index)=>(
                <Card item={item} key={index}/> 
            ))}
        </div>
    </div>
  )
}

export default Equipments