import React from 'react'
import './Training.css'

const Training = () => {
  return (
    <div className='trng-container'>
         <h2>Skill Development</h2>
         <div className='training-container'>
            <div className='tran1'>
                <h3>Training & Development</h3>
                <ul>

                <li>Training offered using workshop facilities of Auto Mikro Tekh’s
                -The Eaagle view , a NABL accredited laboratory</li>

                <li>More practical demonstrations for engineers to hands on experience and exposure to
                advanced / sophisticated machinery / equipments.</li>

                <li>Training offered is of industrial standards & help in understanding the processes</li>

                <li>Engineers in the streams of mechanical / automobile / aeronautical can enhance their
                Technical knowledge.</li>
                </ul>
            </div>
            <div className='tran2'>
                <h3>CNC Training</h3>
                <p>Process sequence, Programming & Simulation, Component
                Manufacturing</p>
            </div>
            <div className='tran3'>
                <h3>Metrology Training </h3>
                <p> Training done on sophisticated instruments capable of giving
                accurate measurement & statistical quality control techniques</p>
            </div>
         </div>
    </div>
  )
}

export default Training