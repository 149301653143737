import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import ProductPage from './components/ProductPage/ProductPage';
import Results from './components/Results/Results';
import { useState } from 'react';
import AirBearingSpindle from './components/AirBearingSpindle/AirBearingSpindle';
import Manufacturers from './components/Manufacturers/Manufacturers';
import Iso from './components/Iso/Iso';
import NotFound from './components/Notfound/NotFound';
import Installation from './components/Installation/Installation';
import Valves from './components/Valves/Valves';

function App() {


  const [search,setSearch]=useState("");


  return (
    <div className="App">
      <Navbar setSearch={setSearch} search={search}/>
      <Routes>
         <Route path='/' element={<Home/>}/>
         <Route path='/searched' element={<Results search={search}/>}/>
         <Route path='/product/:url' element={<ProductPage/>}/>
         <Route path='/air-bearing-spindle' element={<AirBearingSpindle/>}/>
         <Route path='/air-bearing-spindle-installation' element={<Installation/>}/>
         <Route path='/air-bearing-spindle-manufacturer-chennai' element={<Manufacturers/>}/>
         <Route path='/solenoid-valves-manufacturer-chennai' element={<Valves/>}/>
         <Route path='/iso-certified' element={<Iso/>}/>
         <Route path="*" element={<NotFound/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
