import React, { useEffect, useRef, useState } from 'react'
import './Navbar.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ProductList from '../ProductObjects/ProductObject';

const Navbar = ({setSearch,search}) => {

  const navigate=useNavigate();
  const inputRef = useRef(null);
  const location = useLocation();

  const pathnames = ['/searched', '/air-bearing-spindle', '/air-bearing-spindle-manufacturer-chennai','/iso-certified','/air-bearing-spindle-installation','/solenoid-valves-manufacturer-chennai'];

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  
  useEffect(() => {
    if (isSearchOpen) { 
      inputRef.current.focus();
    }
  }, [isSearchOpen]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {

      navigate("/searched")
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setQuery(e.target.value);
  };

  // --------------------------------------------------------------
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (query) {
      const filteredResults = ProductList.filter(product => 
        product.name.toLowerCase().includes(query.toLowerCase())
      );
      setResults(filteredResults);
    } else {
      setResults([]);
    }
  }, [query]);
  // ----------------------------------------------------


  const handleclick=(url)=>{
    setResults([])
    setSearch("")
    setQuery("")
    navigate(`/product/${url}`)
  }

  return (
    <div className='navbar-container'>
       <div className="navbar-logo">
         <Link className='link' to={'/'} title="AutoMikroTekh - Air Bearing Spindle Manufacturer in Chennai"><h1>Auto Mikro Tekh</h1></Link>
        
         <Link className='link-abbr' to={'/'} title="AutoMikroTekh - Air Bearing Spindle Manufacturer in Chennai"><h1>AMT</h1></Link>
       </div>
       
       <ul className={`navbar-link-list ${isNavVisible ? 'visible' : ''}`}>
        {location.pathname==='/'&&(
        <>
        <Link className='link-abbr' to={'/'}><li>Home</li></Link>
        <li><a href="#about">Company</a></li>
        <li><a href="#product">Products</a></li>
        <li><a href="#contact">Contact</a></li>
        </>
        )}
        {location.pathname.startsWith('/product')&&(
          <>
          <Link className='link-abbr' to={'/'}><li>Home</li></Link>
            <li><a href="#similar">Similar</a></li>
            <li><a href="#other">Other</a></li>
            <li><a href="#contact">Contact</a></li>
            </>
        )}
         {pathnames.some(path => location.pathname.startsWith(path))&&(
          <>
          <Link className='link-abbr' to={'/'}><li>Home</li></Link> 
          <Link className='link-abbr' to={'/product/air-bearing-spindle-160000'}><li>Products</li></Link> 
          <li><a href="#contact">Contact</a></li>
            </>
        )}
       </ul>
       
       <div className="navbar-search-icon">
          <div className={`search-bar ${isSearchOpen?'open':''}`}>
            <input type="text" placeholder="Search..." value={search} onChange={(e)=>handleSearchChange(e)} onKeyDown={handleKeyPress} ref={inputRef}/>
            {/* -------------------------------------------------------- */}
            {query && (
              <div className='search-results'>
                {results.length > 0 && (
                  <div>
                    {results.map((product, index) => (
                      <p key={index} className='search-result-item' onClick={()=>handleclick(product.url)}>
                        {product.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            )}
          {/* ------------------------------------------------------ */}
          </div>
          
         <i className="fa fa-search fa-lg" onClick={toggleSearch}></i>
         <i className="fa-solid fa-bars fa-lg ham-icon" onClick={toggleNav}></i>
       </div>

      
    </div>
  )
}

export default Navbar