import React from 'react'
import './Home.css'
import Product from '../Product/Product'
import Aboutus from '../Aboutus/Aboutus'
import Quality from '../Quality/Quality'
import Training from '../Training/Training'
import Laboratory from '../Laboratory/Laboratory'
import Equipments from '../Equipments/Equipments'
import Aim from '../Aim/Aim'
import Infrastructure from '../Infrastructure/Infrastructure'
import AirBearing from '../AirBearing/AirBearing'
import { Helmet } from 'react-helmet'



const Home = () => (
  <div className='home-container'>
    <Helmet>
    <link rel="canonical" href="https://automikrotekh.com/" />
    </Helmet>
    <Aim/>
    <Aboutus/>
    <Quality/>
    <Infrastructure/>
    <Product/>
    <AirBearing/>
    <Laboratory/>
    <Equipments/>
    <Training/>
  </div>
)

export default Home