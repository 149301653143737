import React from 'react'
import './Infrastructure.css'
import m1 from '../../assets/m1.webp'
import m2 from '../../assets/m2.webp'
import m3 from '../../assets/m3.webp'
import m4 from '../../assets/m4.webp'

const Infrastructure = () => {
  return (
    <div className="inf-container">
        <h2>InfraStructure</h2>
        <div className='infra-container'>
            <div className='m12'>
                <img className='m1' src={m1} alt="CNC Machines" />
                <img className='m2' src={m2} alt="CNC Machines" />
            </div>
            <div className='infra-details'>
                <h2>Explore our wide range of precision-engineered products, including air bearing spindles, solenoid valves, and spool valves.</h2>
                <p>A well equipped Machine Shop to manufacture all critical parts.
                Facilities consisting of various CNC sliding head stock machines for precision
                & complex turning.</p>
                
                <p>Our capability is on High Precision turned parts using
                Ferrous and Non Ferrous materials in the range of 2mm to 20mm Diameter.</p>
            </div>
            <div className='m34'>
                <img className='m3' src={m3} alt="CNC Machines" />
                <img className='m4' src={m4} alt="CNC Machines" />
            </div>
        </div>
        <div className="infra-smcontainer">
            <div>
                    <img className='m1' src={m1} alt="CNC Machines" /> 
            </div>
            <div className='m23'>
                <img className='m2' src={m2} alt="CNC Machines" />
                <img className='m3' src={m3} alt="CNC Machines" />
            </div>
            <div>
                <img className='m4' src={m4} alt="CNC Machines" />
            </div>
        </div>
    </div>
  )
}

export default Infrastructure