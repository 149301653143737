
import pr1 from '../../Images/pr1.webp'
import pr2 from '../../Images/pr2.webp'
import pr3 from '../../Images/pr3.webp'
import pr4 from '../../Images/pr4.webp'
import pr5 from '../../Images/pr5.webp'
import pr6 from '../../Images/pr6.webp'


const ProductList=[
    {  
        "productId":1,
        "name":"Air Bearing Spindle - 1,60,000 RPM",
        "url":"air-bearing-spindle-160000",
        "image": pr1,
        "pdf":"/airbearing1.pdf",
        "alt":"Air Bearing Spindle",
        "Meta": "Experience unparalleled precision with our Air Bearing Spindle - 1,60,000 RPM. Achieve exceptional performance and accuracy in your machining processes.",
        "use1":"Air bearings are used in CMMs(Coordinate Measuring Machines) for their exceptional accuracy and resolution1. These machines rely on precise measurements, and air bearings help achieve the necessary precision.",
        "use2":"High-speed machine tool spindles benefit from air bearings. They provide smooth rotation, high precision, and low friction, making them suitable for applications like PCB drilling and laser scanner machining",
        "use3":"Air bearings are crucial in optical metrology systems. They contribute to stability and precision, ensuring accurate measurements in fields such as optics and imaging.",
        "use4":"In roundness testing equipment, air bearings help maintain stability and minimize errors during measurements.",
        "use5":"Air bearings are a natural choice for semiconductor wafer processing due to their high speed, repeatable motion, and cleanroom compatibility",
    },
    {
        "productId":2,
        "name":"Air Bearing Spindle - 60,000 RPM",
        "url":"air-bearing-spindle-60000",
        "image": pr6,
        "alt":"Air Bearing Spindle",
        "pdf":"/airbearing2.pdf",
        "Meta": "Discover the power of our Air Bearing Spindle - 60,000 RPM. Engineered for high-speed precision machining, this spindle delivers superior results with unmatched efficiency.",
        "use1":"Air bearings are used in CMMs(Coordinate Measuring Machines) for their exceptional accuracy and resolution1. These machines rely on precise measurements, and air bearings help achieve the necessary precision.",
        "use2":"High-speed machine tool spindles benefit from air bearings. They provide smooth rotation, high precision, and low friction, making them suitable for applications like PCB drilling and laser scanner machining",
        "use3":"Air bearings are crucial in optical metrology systems. They contribute to stability and precision, ensuring accurate measurements in fields such as optics and imaging.",
        "use4":"In roundness testing equipment, air bearings help maintain stability and minimize errors during measurements.",
        "use5":"Air bearings are a natural choice for semiconductor wafer processing due to their high speed, repeatable motion, and cleanroom compatibility",
    },
    {
        "productId":3,
        "name":"Solenoid valve (1/4') - 3/2 NC",
        "url":"solenoid-valve-three-by-two",
        "image": pr5,
        "alt":"Solenoid valve (1/4') - 3/2 NC",
        "pdf":"/SOLENOIDTHREETWO.pdf",
        "Meta":"Optimize your pneumatic systems with our Solenoid Valve (1/4') - 3/2 NC. Built for reliability and efficiency, this valve ensures smooth operation and precise control.",
        "use1":"Solenoid valves are used to control the flow of refrigerant. They can be designed to open or close in response to an electrical signal, effectively starting or stopping the flow of refrigerant.",
        "use2":"To provide individual temperature control in each refrigerated space of a multiple system",
        "use3":"To control the flow of condensing water in cooling systems",
        "use4":"To regulate the passage of air in pneumatic circuits",
        "use5":"To control water lines, airlines, etc. in industrial machinery, automobiles, and home appliances",
        "data":[
            { property: 'Model', value: 'SV013211' },
            { property: 'Port', value: 'inlet,outlet-G1/4,exhaust-G1/8' },
            { property: 'Medium', value: 'filtered compressed air' },
            { property: 'Ambient Temperature', value: '-10º to +60º C' },
            { property: 'Medium Temperature', value: '+5º to +50º C' },
            { property: 'Orifice (NW)', value: '2.5, 2, 1.2' },
            { property: 'Operating Pressure (bar)', value: '0–7 bar, 0–10 bar, 0–20 bar' },
            { property: 'Flow rate (lpm)', value: '180, 140, 60' },
            { property: 'Voltage (V) ±10%', value: 'AC (50 Hz) 24,48,110,220, DC 12,24,48,110' },
            { property: 'Power Consumption (Watts)', value: '6, 9, 8' },
            { property: 'Class of insulation', value: 'Class F' },
            { property: 'Materials of construction', value: 'Aluminium, Brass, Stainless steel,Nitrile' },
            { property: 'Type of coil protection', value: 'IP65' },
         ]
    },
    {
        "productId":4,
        "name":"Solenoid valve (1/4') - 2/2 NC",
        "url":"solenoid-valve-two-by-two",
        "image": pr3,
        "alt":"Solenoid valve (1/4') - 2/2 NC",
        "pdf":"/SOLENOID VALVE.pdf",
        "Meta": "Enhance your pneumatic applications with our Solenoid Valve (1/4') - 2/2 NC. Designed for versatility and durability, this valve delivers consistent performance in various industrial settings.",
        "use1":"Solenoid valves are used to control the flow of refrigerant. They can be designed to open or close in response to an electrical signal, effectively starting or stopping the flow of refrigerant.",
        "use2":"To provide individual temperature control in each refrigerated space of a multiple system",
        "use3":"To control the flow of condensing water in cooling systems",
        "use4":"To regulate the passage of air in pneumatic circuits",
        "use5":"To control water lines, airlines, etc. in industrial machinery, automobiles, and home appliances",
        "data":[
            { property: 'Model', value: 'SV012211' },
            { property: 'Port', value: 'inlet,outlet-G1/4' },
            { property: 'Medium', value: 'filtered compressed air' },
            { property: 'Ambient Temperature', value: '-10º to +60º C' },
            { property: 'Medium Temperature', value: '+5º to +50º C' },
            { property: 'Orifice (NW)', value: '2' },
            { property: 'Operating Pressure (bar)', value: '0 – 10 bar' },
            { property: 'Flow rate (lpm)', value: '250' },
            { property: 'Voltage (V) ±10%', value: 'AC (50 Hz) 24,48,110,220' },
            { property: 'Power Consumption (Watts)', value: '6' },
            { property: 'Class of insulation', value: 'Class F' },
            { property: 'Materials of construction', value: 'Aluminium, Brass, Stainless steel,Nitrile' },
            { property: 'Type of coil protection', value: 'IP65' },
         ]
    },
    {
        "productId":5,
        "name":"1/4 spool valve 5/2 double solenoid valve ",
        "url":"spool-valve-double-solenoid",
        "image": pr2,
        "alt":"1/4 spool valve 5/2 double solenoid valve ",
        "pdf":"/doublespool.pdf",
        "Meta": "Maximize control and efficiency with our 1/4 Spool Valve 5/2 Double Solenoid Valve. Engineered for reliability and precision, this valve offers seamless integration into pneumatic systems.",
        "use1":"1/4 spool valves are versatile components used in various industrial applications to control the flow of liquids or gases",
        "use2":"Widely used to control pneumatic actuators and cylinders, allowing precise movement and positioning in automation systems.",
        "use3":"Essential in hydraulic circuits for directing fluid flow to different parts of a system, enabling operations like lifting, pressing, and moving.",
        "use4":"Integral in conveyor systems, packaging machines, and robotic arms for efficient and controlled movement of goods.",
        "use5":"Integrated into machinery for controlling implements and attachments hydraulically.",
        "data":[
            { property: 'Model', value: 'SP0152SS11' },
            { property: 'Design', value: 'Spool Type' },
            { property: 'Port', value: 'inlet,outlet-G1/4' },
            { property: 'Flow rate (lpm) Inlet to Outlet ', value: '1200 LPM'},
            { property: 'Flow rate (lpm) Outlet to Exhaust', value: '700 LPM'},
            { property: 'Medium', value: 'Compressed air – Dry , Filtered, Lubricated' },
            { property: 'Ambient Temperature', value: '-10º to +60º C' },
            { property: 'Medium Temperature', value: '+5º to +50º C' },
            { property: 'Working Pressure Range (bar)', value: '2 – 10 bar' },
           
            { property: 'Voltage (V) ±10%', value: 'AC (50 Hz) 24,48,110,220, DC 12,24,48,110' },
            { property: 'Power Consumption (Watts)', value: 'AC-6 VA, DC-5W' },
            { property: 'Class of insulation', value: 'Class F' },
            { property: 'Materials of construction', value: 'Aluminium, Nitril, Brass, POM, PBT, Zinc' },
            { property: 'Type of coil protection', value: 'IP65' },
         ]
    },
    {
        "productId":6,
        "name":"1/4 Spool valve 5/2 Single solenoid spring return valve ",
        "url":"spool-valve-single-solenoid",
        "image": pr4,
        "alt":"1/4 Spool valve 5/2 Single solenoid spring return valve ",
        "pdf":"/singlespool.pdf",
        "Meta":"Experience superior performance with our 1/4 Spool Valve 5/2 Single Solenoid Spring Return Valve. Designed for smooth operation and quick response, this valve is ideal for demanding applications.",
        "use1":"1/4 spool valves are versatile components used in various industrial applications to control the flow of liquids or gases",
        "use2":"Widely used to control pneumatic actuators and cylinders, allowing precise movement and positioning in automation systems.",
        "use3":"Essential in hydraulic circuits for directing fluid flow to different parts of a system, enabling operations like lifting, pressing, and moving.",
        "use4":"Integral in conveyor systems, packaging machines, and robotic arms for efficient and controlled movement of goods.",
        "use5":"Integrated into machinery for controlling implements and attachments hydraulically.",
        "data":[
            { property: 'Model', value: 'SP0152SR11' },
            { property: 'Design', value: 'Spool Type' },
            { property: 'Port', value: 'inlet,outlet-G1/4,Exhaust' },
            { property: 'Flow rate (lpm) Inlet to Outlet ', value: '1200 LPM'},
            { property: 'Flow rate (lpm) Outlet to Exhaust', value: '700 LPM'},
            { property: 'Medium', value: 'Compressed air – Dry , Filtered, Lubricated' },
            { property: 'Ambient Temperature', value: '-10º to +60º C' },
            { property: 'Medium Temperature', value: '+5º to +50º C' },
            { property: 'Working Pressure Range (bar)', value: '2 – 10 bar' },
           
            { property: 'Voltage (V) ±10%', value: 'AC (50 Hz) 24,48,110,220, DC 12,24,48,110' },
            { property: 'Power Consumption (Watts)', value: 'AC-6 VA, DC-5W' },
            { property: 'Class of insulation', value: 'Class F' },
            { property: 'Materials of construction', value: 'Aluminium, Nitril, Brass, POM, PBT, Zinc' },
            { property: 'Type of coil protection', value: 'IP65' },
         ]
 
    },
   
   
];

export default ProductList;









