import React, { useState } from 'react'
import './Valves.css'

import pr2 from '../../Images/pr2.webp'
import pr3 from '../../Images/pr3.webp'
import pr4 from '../../Images/pr4.webp'
import pr5 from '../../Images/pr5.webp'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'


const Valves = () => {

  const [valveone, setValveone] = useState(false);
  const [valvetwo, setValvetwo] = useState(false);
  const [valvethree, setValvethree] = useState(false);
  const [valvefour, setValvefour] = useState(false);

  const handlevalveone = () => {
    setValveone(!valveone);
   };
 
   const handlevalvetwo = () => {
    setValvetwo(!valvetwo);
   };
 
   const handlevalvethree = () => {
    setValvethree(!valvethree);
   };
 
   const handlevalvefour = () => {
    setValvefour(!valvefour);
   };

  return (
    <div className='valve-container'>
      <Helmet>
        <title>Solenoid Valves by AutoMikroTekh - Reliable Solutions for Industrial Automation</title>
        <meta name="description" content="Discover AutoMikroTekh's premium solenoid valves, designed for reliable and efficient industrial automation. Explore our diverse range of high-performance valves tailored for various applications." />
        <link rel="canonical" href="https://automikrotekh.com/solenoid-valves-manufacturer-chennai" />
      </Helmet>
      <h1>High Quality Solenoid Valves and Spool Valves</h1>
      <p>Welcome to AutoMikroTekh, your trusted <strong> manufacturer of high-quality solenoid and spool valves in Chennai</strong>.
      Our valves are designed for precision control of fluid and air flow in various industrial applications.</p>
      <div>
        <div>
            <h2>Solenoid Valves ?</h2>
            <p className='sv-def'><strong> Solenoid valves</strong> are electromechanically operated valves that control the flow of liquids or gases.
                They consist of a solenoid (an electric coil) and a valve body. When an electric current passes through the solenoid,
                it creates a magnetic field that moves a plunger inside the valve, opening or closing the flow path.</p>
        </div>
        <div className='sv-container'>
            <h2>1/4 Solenoid Valve 3/2 Normally Closed (NC)</h2>
            <div className='sv-details'>
              <Link to={'/product/solenoid-valve-three-by-two'}><img src={pr5} alt="1/4 Solenoid Valve 3/2 Normally Closed (NC)" /></Link> 
                
                <div className='sv-exp'>
                    <h2  onClick={handlevalveone} style={{ cursor: 'pointer' }}>More Details <i className={`fa-solid ${valveone?'fa-circle-minus':'fa-plus-circle'}`}></i></h2>
                    <div className={`content ${valveone  ? 'visible' : ''}`}>
                      <p>1/4 refers to the port size of the valve, which is 1/4 inch. It indicates the diameter of the pipe or tube that can be connected to the valve.</p>
                      <p><strong>Three Ports:</strong> There are three connections on the valve (commonly labeled as P, A, and R). P is the pressure port, A is the actuator port, and R is the return or exhaust port.</p>
                      <p><strong>Two Positions:</strong> The valve can be in one of two states, typically controlling the direction of flow between the ports.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='sv-container'>
            <h2>1/4 Solenoid Valve 2/2 Normally Closed (NC)</h2>
            <div className='sv-details'>
               <Link to={'/product/solenoid-valve-two-by-two'}><img src={pr3} alt="1/4 Solenoid Valve 2/2 Normally Closed (NC)" /></Link> 
                <div className='sv-exp'>
                    <h2  onClick={handlevalvetwo} style={{ cursor: 'pointer' }}>More Details <i className={`fa-solid ${valvetwo?'fa-circle-minus':'fa-plus-circle'}`}></i></h2>
                    <div className={`content ${valvetwo  ? 'visible' : ''}`}>
                      <p>1/4 refers to the port size of the valve, which is 1/4 inch. It indicates the diameter of the pipe or tube that can be connected to the valve.</p>
                      <p><strong>Two Ports:</strong> There are two connections on the valve, typically labeled as P (Pressure) and A (Actuator).</p>
                      <p><strong>Two Positions:</strong> The valve can be in one of two states, typically controlling the direction of flow between the ports.</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div>
        <h2>Spool Valves ?</h2>
        <p className='sv-def'><strong>Spool valves</strong> are used to direct the flow of fluid or gas in hydraulic or pneumatic systems.
             They consist of a cylindrical spool that moves within a valve body to control the flow path.</p>
        <div className='sv-container'>
            <h2>1/4" Spool Valve 5/2 Double Solenoid Valve</h2>
            <div  className='sv-details'>
               <Link to={'/product/spool-valve-double-solenoid'}> <img src={pr2} alt="1/4 Spool Valve 5/2 Double Solenoid" /></Link>
                <div>
                <h2  onClick={handlevalvethree} style={{ cursor: 'pointer' }}>More Details <i className={`fa-solid ${valvethree?'fa-circle-minus':'fa-plus-circle'}`}></i></h2>
                <div className={`content ${valvethree  ? 'visible' : ''}`}>
                   <p>The 1/4" 5/2 Double Solenoid Spool Valve features two solenoids for controlling the position of the spool. It is used in applications requiring the flow direction to be changed automatically.</p>
                </div>
                </div>
            </div>
        </div>
        <div className='sv-container'>
            <h2>1/4" Spool Valve 5/2 Single Solenoid Spring Return Valve</h2>
            <div  className='sv-details'>
              <Link to={'/product/spool-valve-single-solenoid'}><img src={pr4} alt="1/4 Spool Valve 5/2 Single Solenoid Spring Return valve" /></Link>
              <div>
                <h2  onClick={handlevalvefour} style={{ cursor: 'pointer' }}>More Details <i className={`fa-solid ${valvefour?'fa-circle-minus':'fa-plus-circle'}`}></i></h2>
                <div className={`content ${valvefour  ? 'visible' : ''}`}>
                    <p>The 1/4" 5/2 Single Solenoid Spring Return Spool Valve has a single solenoid and a spring mechanism that returns the spool to its original position when the solenoid is de-energized.</p>         
                </div>
              </div>
            </div>
        </div>
        <div>
          <h2>conclusion</h2>
          <p className='sv-def'><strong> AutoMikroTekh</strong> stands as a trusted <strong>solenoid valve manufacturer in Chennai</strong> , offering high-quality solutions tailored to your needs.
           Whether you are looking for solenoid valves or spool valves, our extensive product range and dedicated customer support ensure that you
           receive the best in terms of performance and reliability. <strong>Trust AutoMikroTekh for all your solenoid valve and spool valve
           requirements</strong> , and experience the difference that quality and expertise can make.</p>
        </div>
        </div>
    </div>
  )
}

export default Valves