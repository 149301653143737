import React from 'react'
import './AirBearing.css'
import pr1 from '../../Images/pr1.webp'
import { Link } from 'react-router-dom'

const AirBearing = () => {
  return (
    <div className='airbearing-container'>
       <Link to={'/product/air-bearing-spindle-160000'}><img src={pr1} alt="Air Bearing Spindle" /></Link>
       <div className='airbearing-details'>
          <h1>What is Air Bearing Spindle?</h1>
          <p>
            An air bearing spindle is a type of spindle that uses a thin film of pressurized air to support and rotate the spindle shaft.
            This air film eliminates direct mechanical contact between the moving parts,resulting in extremely low friction, high precision,
            and virtually no wear.
          </p>
          <p>We are one of the leading manufacturers of high-precision air bearing spindles in Chennai with highest RPM of 1,60,000 revolutions per minute</p>
          <div className='links'>
            <Link to={"/air-bearing-spindle"} className='learn'>Learn more about Air Bearing Spindle</Link>
            <Link to={"/air-bearing-spindle-manufacturer-chennai"} className='learn'>Why Choose Us?</Link>
          </div>
       </div>
       
    </div>
  )
}

export default AirBearing