import React, { useState } from 'react'
import './Product.css'
import ProductList from '../ProductObjects/ProductObject'
import Card from '../Card/Card'

const Product = () => {

  const [startIndex, setStartIndex] = useState(0);
  const productsPerPage = 8;

  const handleNextPage = () => {
    setStartIndex((prevStartIndex) => prevStartIndex + productsPerPage);
  };

  const handlePrevPage = () => {
    setStartIndex((prevStartIndex) => Math.max(0, prevStartIndex - productsPerPage));
  };

  const visibleProducts = ProductList.slice(startIndex, startIndex + productsPerPage);



  return (
    <div className='product-container' id='product'>
      <div className="pagination">
          <button onClick={()=>handlePrevPage()} disabled={startIndex === 0} aria-label="Previous Page" className='pagination-btn left'>
            <i className="fa-solid fa-arrow-left fa-lg"></i>
          </button> 
          <h2>Products</h2>
          <button onClick={()=>handleNextPage()} disabled={startIndex + productsPerPage >= ProductList.length} aria-label="Next Page" className='pagination-btn right'>
            <i className="fa-solid fa-arrow-right fa-lg"></i>
          </button>
      </div>
      <div className="products">
         {visibleProducts.map((item,index)=>(
              <Card item={item} key={index}/> 
         ))}
      </div>
      
    </div>
  )
}

export default Product