import React from 'react'
import './ResultCard.css'
import { useNavigate } from 'react-router-dom'

const ResultCard = ({item}) => {
  const navigate=useNavigate();


  return (
    <div className='result-cardcontainer' onClick={()=>navigate(`/product/${item.productId}`)}>
       <img src={item.image} alt="" />
       <div className='pr-desc'>
          <p className='pr-name'>{item.name}</p>
          <p className='pr-use'>{item.use1}</p>
          <p className='pr-use'>{item.use2}</p>
       </div>
    </div>
  )
}

export default ResultCard