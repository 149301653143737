import React, { useEffect } from 'react'
import './Results.css'
import ProductList from '../ProductObjects/ProductObject'
import ResultCard from '../ResultCard/ResultCard'

const Results = ({search}) => {
 
    const productResults=search?ProductList.filter((product)=>product.name.toLowerCase().includes(search.toLowerCase())):ProductList;
    // console.log("productResults",productResults)
    // console.log("searches",search)
    useEffect(() => {
        window.scrollTo(0, 0);
      },[]);


  return (
    <div className='results-container'>
        {productResults.length===0?(
            <div className='noresults'>No Results Found</div>
        ):(
        <div className='results-card-container'>
            {
                productResults.map((products,index)=>(
                    <ResultCard item={products} key={index} />
                ))
            }
        </div>
        )}
    </div>
  )
}

export default Results