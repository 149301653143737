import React, { useEffect } from 'react'
import './Installation.css'

const Installation = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0);
      },[]);

  return (
    <div className='install-container'>
        <div>
            <h1>Step-by-Step Guide to Installing an Air Bearing Spindle</h1>
            <p>Installing an air bearing spindle requires careful attention to detail and adherence to the manufacturer's
            guidelines to ensure proper operation and longevity. Below is a step-by-step guide to help you through the installation process:</p>

            <div className='install'>
                <h2>Preparation:</h2>
                <ul>
                    <li><strong>Read the Manual:</strong> Start by thoroughly reading the installation and operation manual provided by the manufacturer.</li>
                    <li><strong>Workspace:</strong> Ensure your workspace is clean, free of dust, and well-organized.</li>
                    <li><strong>Tools and Equipment:</strong> Gather all necessary tools and equipment. This typically includes wrenches, screwdrivers, torque wrenches, and alignment tools.</li>
                </ul>
            </div>
            <div className='install'>
                <h2>Unpacking:</h2>
                <ul>
                    <li>Carefully unpack the spindle, taking care not to damage any components. Inspect the spindle for any visible damage that might have occurred during shipping.</li>
                </ul>
            </div>
            <div className='install'>
                <h2>Mounting the Spindle:</h2>
                <ul>
                    <li><strong>Mounting Surface:</strong> Ensure the mounting surface is clean, flat, and free from any contaminants. It should also be rigid to prevent vibrations.</li>
                    <li><strong>Alignment:</strong> Position the spindle on the mounting surface and use alignment tools to ensure it is perfectly aligned. Misalignment can cause operational issues and reduce the lifespan of the spindle.</li>
                </ul>
            </div>
            <div className='install'>
                <h2>Securing the Spindle:</h2>
                <ul>
                    <li>Secure the spindle to the mounting surface using the appropriate bolts and fasteners. Use a torque wrench to tighten the bolts to the manufacturer's specified torque settings.</li>
                </ul>
            </div>
            <div className='install'>
                <h2>Connecting Air Supply:</h2>
                <ul>
                    <li><strong>Clean Air:</strong> Ensure the air supply is clean and dry. Use an appropriate filter to remove any contaminants from the air.</li>
                    <li><strong>Air Pressure:</strong> Connect the air supply to the spindle and set the air pressure according to the manufacturer's specifications. Proper air pressure is crucial for optimal performance.</li>
                    <li><strong>Hoses and Fittings:</strong> Use the correct hoses and fittings to connect the air supply. Ensure there are no leaks and that the connections are secure.</li>
                </ul>
            </div>
            <div className='install'>
                <h2>Electrical Connections:</h2>
                <ul>
                    <li><strong>Controller:</strong> Connect the spindle to the controller or drive unit. Follow the wiring diagram provided by the manufacturer.</li>
                    <li><strong>Check Voltage:</strong> Verify that the voltage and electrical parameters match the specifications for the spindle.</li>
                    <li><strong>Grounding:</strong> Ensure proper grounding to prevent electrical interference and static buildup.</li>
                </ul>
            </div>
            <div className='install'>
                <h2>Initial Testing:</h2>
                <ul>
                    <li><strong>Spin Test:</strong> Slowly spin up the spindle to a low RPM and listen for any unusual noises or vibrations. Gradually increase the speed while monitoring the spindle’s behavior.</li>
                    <li><strong>Temperature:</strong> Check the spindle temperature during operation to ensure it stays within the recommended range.</li>
                </ul>
            </div>
            <div className='install'>
                <h2>Calibration and Adjustment:</h2>
                <ul>
                    <li><strong>Runout:</strong> Measure the runout of the spindle using a dial indicator. Adjust as necessary to achieve the specified runout tolerance.</li>
                    <li><strong>Balance:</strong> Balance the spindle if required. An unbalanced spindle can lead to poor performance and premature wear.</li>
                </ul>
            </div>
            <div className='install'>
                <h2>Lubrication:</h2>
                <ul>
                    <li><strong>Lubrication System:</strong> If the spindle requires lubrication, ensure the lubrication system is properly set up and functioning. Follow the manufacturer’s recommendations for lubrication intervals and types of lubricants.</li>
                </ul>
            </div>
            <div className='install'>
                <h2>Final Checks:</h2>
                <ul>
                    <li><strong>Safety Interlocks:</strong> Ensure all safety interlocks and emergency stop functions are operational.</li>
                    <li><strong>Documentation:</strong> Document the installation process, including alignment measurements, torque settings, and any issues encountered.</li>
                </ul>
            </div>
            <div className='install'>
                <h2>Start-up and Operation:</h2>
                <ul>
                    <li><strong>Gradual Increase:</strong> Gradually bring the spindle up to full operational speed while monitoring its performance.</li>
                    <li><strong>Monitoring: </strong>Regularly monitor the spindle for signs of wear, unusual noises, or other issues. Perform routine maintenance as recommended by the manufacturer.</li>
                </ul>
            </div>
            
        </div>
    </div>
  )
}

export default Installation