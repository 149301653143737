import React from 'react'
import './Footer.css'


const Footer = () => {
  return (
    <div className='footer-container' id='contact'>
         <div className='footer-links'>

            <div className='footer-left'>
                <p className='text'>Feel free to contact us</p>
                <p className='ceo'>Mr. R. GOPALA KRISHNAN - CEO</p>
                <p><i className="fas fa-envelope" aria-hidden="true"></i><a href="mailto:gopalakrishnan@automikrotekh.com">Email to: gopalakrishnan@automikrotekh.com</a></p>
                <p><i className="fas fa-phone-alt" aria-hidden="true"></i>Phone1: <a href="tel:8838052929">88380 52929</a></p>
                <p><i className="fas fa-phone-alt" aria-hidden="true"></i>Phone2: <a href="tel:9444056612">94440 56612 </a></p>
                <p><i className="fas fa-map-marker-alt" aria-hidden="true"></i>Location: <a href="https://www.google.com/maps?q=Auto+Mikro+Tekh+238%2FA%2C+Industrial+Complex+Nehru+Nagar+Perungudi+Post%2C+3RD+Link%2C+Chennai%2C+Tamil+Nadu+600096" target="_blank" rel="noreferrer">Google Map</a></p>
            </div>

              
            <div className="footer-center">
                <h3>Auto Mikro Tekh</h3>
                <p>238/A, Industrial Complex</p>
                <p>3RD Link street, Nehru Nagar, I.T Highway</p>
                <p>South India , Tamil Nadu</p>
                <p>Chennai 600096</p>
            </div>
      


            <div className="footer-right">
              <h3>Product Development Team</h3>
              <p><i className="fas fa-phone-alt" aria-hidden="true"></i>RADHAKRISHNAN : <a href="tel:7418196155">74181 96155</a></p>
              <p><i className="fas fa-phone-alt" aria-hidden="true"></i>KALYANASUNDARAM : <a href="tel: 9092135586">90921 35586</a></p>
            </div>

         </div>
         <hr />
         <div className='footer-bottom'>
            <p>&copy; {new Date().getFullYear()} Auto Mikro Tekh Corporation. All Rights Reserved</p>
            {/* <div className='bottom-links'>
              <p>Terms & Conditions</p>
              <p>Privacy Policy</p>
              <p>Cookie settings</p>
            </div> */}
         </div>
    </div>
  )
}

export default Footer